import React, {useState} from 'react'
import styled from '@emotion/styled'

import PlacesForm from "./places-form"
import FormButton from "./form-button.style"
import Group from "./group"
import UserIcon from "./icons/user"
import LocationIcon from "./icons/location"
import CalendarIcon from "./icons/calendar"
import HotelIcon from "./icons/hotel"
import RestaurantIcon from "./icons/restaurant"
import TrainIcon from "./icons/train"
import BeerIcon from "./icons/beer"

const raw = `
Albania\tAlbania\tEuropa\t4.54\t4.53\t22.65\t0.49\t0.36\t1.36\t1.36\tBunk'Art, Tirana\t$4.52\t$28.84\t$10.82\t$90.13\t$30.16
Argelia\tAlgeria\tAfrica\t3.35\t2.51\t12.54\t0.26\t0.21\t0.42\t1.67\t\t\t$13.62\t$22.90\t$42.57\t$32.79
Argentina\tArgentina\tAmerica\t5.13\t5.86\t21.76\t0.93\t0.35\t0.42\t1.67\t\t\t$24.77\t$16.06\t$77.41\t$33.81
Armenia\tArmenia\tEuropa\t5.02\t5.23\t25.12\t0.51\t0.21\t0.21\t1.26\tHistory Museum Of Armenia\t$4.18\t$30.91\t$10.98\t$96.60\t$31.36
Australia\tAustralia\tOceania\t8.12\t13.64\t54.54\t1.5\t2.86\t1.36\t5.45\t\t\t$64.55\t$21.57\t$201.71\t$58.67
Austria\tAustria\tEuropa\t8.83\t12.2\t55.45\t0.65\t2.66\t1.97\t4.44\tPalacio imperial de Hofburg\t$13.36\t$76.12\t$23.99\t$237.88\t$73.18
Azerbaiyán\tAzerbaijan\tAsia\t4.7\t5.87\t23.49\t0.47\t0.18\t0.35\t0.88\t\t\t$25.65\t$9.87\t$80.16\t$25.97
Baréin\tBahrain\tMedio Oriente\t6.1\t6.63\t39.78\t0.69\t0.8\t1.33\t9.28\t\t\t$42.72\t$21.04\t$133.49\t$42.16
Bangladés\tBangladesh\tAsia\t5.3\t1.77\t7.96\t0.31\t0.35\t0.41\t3.54\t\t\t$9.28\t$21.82\t$28.99\t$35.20
Bielorrusia\tBelarus\tEuropa\t4.9\t9.6\t25\t0.65\t0.3\t0.28\t2\tPalacio de Mir\t$6.62\t$35.45\t$13.91\t$110.79\t$41.18
Bélgica\tBelgium\tEuropa\t8.89\t16.63\t66.54\t0.94\t2.33\t2.22\t4.44\tCastillo de los Condes, Gante\t$11.13\t$86.20\t$22.51\t$269.37\t$73.65
Bolivia\tBolivia\tAmerica\t5.42\t2.89\t20.04\t1.05\t0.29\t1.45\t2.17\t\t\t$21.76\t$12.86\t$67.99\t$28.22
Bosnia y Herzegovina\tBosnia And Herzegovina\tEuropa\t3.97\t3.97\t22.68\t0.57\t1.02\t0.67\t1.42\tMuseo de la Infancia en Guerra, Sarajevo\t$5.69\t$31.03\t$13.80\t$96.96\t$34.01
Brasil\tBrazil\tAmerica\t6.09\t4.82\t24.12\t0.71\t1.04\t0.72\t1.81\t\t\t$27.60\t$12.22\t$86.24\t$32.01
Bulgaria\tBulgaria\tEuropa\t4.55\t5.67\t22.68\t0.55\t0.74\t0.45\t1.25\tMuseo Nacional de Historia, Sofia\t$5.69\t$31.28\t$10.93\t$97.75\t$33.42
Canadá\tCanada\tAmerica\t7.58\t12.09\t49.13\t1.69\t2.29\t1.42\t4.53\t\t\t$57.83\t$20.00\t$180.72\t$53.52
Chile\tChile\tAmerica\t5.32\t6.42\t35.94\t1.13\t0.94\t1.28\t3.21\t\t\t$39.68\t$13.09\t$124.00\t$33.16
China\tChina\tAsia\t4.77\t3.55\t21.31\t0.61\t0.28\t0.34\t0.99\t\t\t$22.83\t$10.34\t$71.34\t$24.60
Colombia\tColombia\tAmerica\t4.45\t3.04\t17.56\t0.79\t0.67\t1.46\t0.88\t\t\t$19.96\t$10.46\t$62.38\t$24.53
Costa Rica\tCosta Rica\tAmerica\t7.03\t7.04\t35.22\t2.18\t0.77\t1.16\t2.64\t\t\t$39.96\t$14.54\t$124.88\t$39.36
Croacia\tCroatia\tEuropa\t5.97\t7.45\t36.53\t1.06\t1.49\t0.89\t2.39\tMurallas de Dubrovnik\t$32.27\t$72.06\t$20.40\t$225.19\t$76.10
Chipre\tCyprus\tEuropa\t6.67\t13.31\t49.9\t0.8\t1.66\t1.11\t3.33\tTumbas de los Reyes, Pafos\t$2.78\t$59.72\t$16.32\t$186.63\t$49.87
República Checa\tCzech Republic\tEuropa\t5.66\t5.86\t26.06\t0.55\t1.04\t1.22\t1.52\tCastillo de Praga\t$17.81\t$46.52\t$15.00\t$145.38\t$52.62
Dinamarca\tDenmark\tEuropa\t11.15\t17.81\t77.16\t1.38\t3.41\t2.23\t6.68\tJardines de Tivoli, Copenhague\t$19.35\t$106.93\t$30.41\t$334.16\t$98.07
República Dominicana\tDominican Republic\tAmerica\t6.59\t5.85\t37.86\t1.08\t0.47\t2.84\t2.08\t\t\t$40.29\t$14.06\t$125.90\t$35.11
Ecuador\tEcuador\tAmerica\t6\t3.5\t30\t1.03\t0.3\t1.5\t1.5\t\t\t$31.42\t$11.46\t$98.18\t$28.59
Egipto\tEgypt\tMedio Oriente\t4.65\t3.41\t18.61\t0.31\t0.31\t0.19\t1.86\t\t\t$20.00\t$10.56\t$62.49\t$24.20
Estonia\tEstonia\tEuropa\t6.67\t8.87\t44.36\t0.66\t1.66\t0.67\t4.13\tPalacio Kadriorg, Tallin\t$8.90\t$58.05\t$19.00\t$181.40\t$54.09
Finlandia\tFinland\tEuropa\t8.89\t12.2\t66.54\t1.46\t3.33\t1.77\t6.65\tSeurasaari Open-Air Museum, Helsinki\t$11.13\t$86.48\t$23.98\t$270.26\t$73.21
Francia\tFrance\tEuropa\t8.89\t14.97\t55.45\t0.71\t1.77\t1.66\t6.65\tMuseo de Louvre\t$18.92\t$81.04\t$23.58\t$253.25\t$79.50
Georgia\tGeorgia\tEuropa\t4.51\t5.19\t20.76\t0.41\t0.17\t0.24\t1.04\tMuseo Nacional de Georgia\t$5.21\t$27.59\t$21.64\t$86.22\t$41.81
Alemania\tGermany\tEuropa\t8.89\t11.09\t49.9\t0.43\t2.99\t2.22\t3.88\tCastillo Neuschwanstein\t$14.47\t$71.84\t$21.35\t$224.49\t$71.10
Grecia\tGreece\tEuropa\t6.67\t11.09\t37.7\t0.83\t1.55\t0.89\t4.44\tAcropolis y antigua citadela, Atenas\t$22.26\t$65.35\t$17.88\t$204.22\t$68.50
Hong Kong\tHong Kong\tAsia\t4.48\t6.39\t51.13\t1.76\t1.28\t1.09\t6.39\t\t\t$55.20\t$13.77\t$172.49\t$33.44
Hungría\tHungary\tEuropa\t5.06\t6.7\t30.17\t0.35\t1.17\t1.01\t1.51\tCasa del Terror, Budapest\t$10.11\t$43.60\t$16.00\t$136.24\t$45.62
Islandia\tIceland\tEuropa\t13.01\t20\t102.9\t1.84\t3.87\t2.47\t9.88\tTales from Iceland\t$18.00\t$132.22\t$23.81\t$413.18\t$97.41
India\tIndia\tAsia\t3.54\t2.12\t9.89\t0.4\t0.28\t0.21\t1.84\t\t\t$11.21\t$10.36\t$35.03\t$20.52
Indonesia\tIndonesia\tAsia\t3.22\t1.79\t10.71\t0.44\t0.29\t0.36\t2.5\t\t\t$11.88\t$9.15\t$37.13\t$18.40
Irán\tIran\tMedio Oriente\t4.75\t4.75\t18\t0.42\t0.2\t0.24\t0.71\t\t\t$19.95\t$19.42\t$62.34\t$34.49
Irak\tIraq\tMedio Oriente\t7\t6\t24.5\t0.58\t0.42\t2.5\t2\t\t\t$27.22\t#¡DIV/0!\t$85.06\t#¡DIV/0!
Irlanda\tIreland\tEuropa\t8.89\t16.63\t66.54\t1.44\t2.77\t1.55\t5.54\tGuinness Storehouse, Dublin\t$27.80\t$103.19\t$24.75\t$322.46\t$93.94
Israel\tIsrael\tMedio Oriente\t14.37\t17.27\t63.31\t1.21\t1.7\t1.09\t8.35\t\t\t$72.05\t$21.92\t$225.16\t$72.54
Italia\tItaly\tEuropa\t8.89\t16.63\t55.45\t0.43\t1.66\t1.55\t4.99\tColiseo de Roma\t$17.81\t$80.31\t$22.06\t$250.96\t$78.03
Japón\tJapan\tAsia\t6.22\t8.27\t36.78\t1.14\t1.89\t3.77\t4.14\t\t\t$43.14\t$14.62\t$134.81\t$40.25
Jordania\tJordan\tMedio Oriente\t7.05\t7.05\t35.24\t0.59\t0.7\t0.56\t7.05\t\t\t$38.36\t$14.70\t$119.87\t$37.84
Kazajistán\tKazakhstan\tAsia\t4.29\t5.18\t25.88\t0.47\t0.23\t0.26\t1.29\t\t\t$27.67\t$15.57\t$86.47\t$30.26
Kenia\tKenya\tAfrica\t6.88\t4.93\t29.55\t0.99\t0.79\t1.97\t2.36\t\t\t$32.55\t$13.97\t$101.72\t$35.23
Kosovo\tKosovo (Disputed Territory)\tN/A\t2.78\t3.33\t16.63\t0.43\t0.44\t0.55\t1.66\t\t\t$18.45\t$34.69\t$57.66\t$44.89
Letonia\tLatvia\tEuropa\t5.56\t7.76\t38.81\t0.62\t1.28\t0.67\t2.77\tCasa de los Cabezas Negras\t$6.68\t$49.46\t$13.81\t$154.56\t$42.55
Líbano\tLebanon\tMedio Oriente\t8\t10\t50\t0.8\t1.32\t2\t3.33\t\t\t$55.00\t$23.28\t$171.88\t$52.72
Lituania\tLithuania\tEuropa\t5.56\t7.76\t33.27\t0.75\t1.11\t0.61\t3.33\tMuseo de Trakai\t$8.90\t$46.14\t$12.87\t$144.18\t$43.62
Macedonia\tMacedonia\tEuropa\t2.89\t4.51\t17.08\t0.43\t0.63\t0.45\t1.5\tGaleria Nacional de Macedonia\t$0.90\t$20.64\t$12.08\t$64.49\t$24.96
Malasia\tMalaysia\tAsia\t3.37\t2.4\t14.41\t0.52\t0.72\t0.48\t3.6\t\t\t$16.54\t$9.48\t$51.68\t$20.58
Malta\tMalta\tEuropa\t8.89\t16.63\t66.54\t0.91\t1.66\t2.22\t2.77\tTemplos Hagar Qim\t$11.13\t$84.91\t$17.29\t$265.34\t$67.06
México\tMexico\tAmerica\t4.71\t5.19\t25.94\t0.76\t0.47\t0.52\t1.56\t\t\t$28.46\t$11.63\t$88.93\t$27.94
Montenegro\tMontenegro\tEuropa\t3.33\t5.54\t27.72\t0.67\t1\t0.55\t2.22\tPalacio del Rey Nikola\t$5.56\t$36.44\t$13.10\t$113.88\t$33.53
Marruecos\tMorocco\tAfrica\t5.73\t3.12\t16.09\t0.61\t0.42\t0.62\t3.12\t\t\t$17.98\t$15.40\t$56.18\t$31.43
Nepal\tNepal\tAsia\t6.61\t2.2\t10.58\t0.32\t0.18\t0.44\t2.65\t\t\t$11.63\t$9.66\t$36.35\t$25.76
Países Bajos\tNetherlands\tEuropa\t8.89\t16.63\t66.54\t0.9\t3.33\t2.44\t4.99\tMuseo Rijksmuseum, Amsterdam\t$22.26\t$98.52\t$21.86\t$307.87\t$86.09
Nueva Zelanda\tNew Zealand\tOceania\t7.54\t11.84\t55.47\t1.27\t2.22\t1.93\t5.55\t\t\t$63.15\t$23.42\t$197.35\t$56.05
Noruega\tNorway\tEuropa\t12.09\t19.61\t87.16\t2.25\t3.92\t1.53\t9.7\tThe Fram Museum, Oslo\t$13.17\t$113.15\t$38.71\t$353.60\t$105.76
Pakistan\tPakistan\tMedio Oriente\t4.16\t1.78\t9.69\t0.37\t0.19\t0.19\t1.94\t\t\t$10.66\t$20.32\t$33.32\t$31.17
Palestina\tPalestinian Territory\tMedio Oriente\t7.18\t7.19\t28.78\t0.84\t1\t0.72\t2.73\t\t\t$33.14\t$14.32\t$103.57\t$38.71
Panamá\tPanama\tAmerica\t7\t10\t35\t1.58\t0.25\t2\t2\t\t\t$39.60\t$15.20\t$123.76\t$41.28
Perú\tPeru\tAmerica\t4.58\t2.97\t17.79\t0.92\t0.44\t1.48\t2.08\t\t\t$19.84\t$11.06\t$61.99\t$24.99
Filipinas\tPhilippines\tAsia\t2.97\t2.95\t15.74\t0.76\t0.2\t0.27\t1.18\t\t\t$17.29\t$9.45\t$54.04\t$19.50
Polonia\tPoland\tEuropa\t4.93\t5.43\t25.84\t0.48\t0.83\t0.6\t2.07\tAuschwitz-Birkenau Memorial\t$12.99\t$41.12\t$13.65\t$128.49\t$44.07
Portugal\tPortugal\tEuropa\t6.67\t8.87\t33.27\t0.63\t1.72\t0.52\t2\tPalacio de la Pena\t$14.81\t$53.26\t$14.48\t$166.43\t$55.57
Catar\tQatar\tMedio Oriente\t6.87\t6.87\t54.93\t0.55\t0.82\t0.51\t12.36\t\t\t$56.99\t$31.33\t$178.10\t$54.13
Rumanía\tRomania\tEuropa\t4.65\t5.8\t23.2\t0.55\t0.58\t0.49\t1.39\tCastillo de Bran\t$9.46\t$35.08\t$13.94\t$109.62\t$40.21
Rusia\tRussia\tEuropa\t4.78\t7.86\t31.46\t0.56\t0.39\t0.24\t1.57\tMuseo del Hermitage\t$6.31\t$40.51\t$13.20\t$126.59\t$38.27
Arabia Saudita\tSaudi Arabia\tMedio Oriente\t6.4\t5.33\t26.67\t0.56\t0.8\t1.33\t1.33\t\t\t$29.64\t$19.54\t$92.63\t$39.83
Serbia\tSerbia\tEuropa\t4.81\t5.66\t21.94\t0.48\t0.84\t0.61\t1.51\tMuseo Nikola Tesla\t$4.73\t$29.80\t$14.06\t$93.12\t$36.23
Singapur\tSingapore\tAsia\t5.9\t8.83\t44.14\t1.27\t1.32\t0.4\t7.36\t\t\t$49.38\t$14.04\t$154.31\t$38.58
Eslovaquia\tSlovakia\tEuropa\t5.89\t5.54\t27.72\t0.66\t0.78\t0.89\t1.66\tCastillo de Bratislava\t$11.13\t$41.26\t$16.98\t$128.94\t$47.65
Eslovenia\tSlovenia\tEuropa\t5.56\t8.87\t36.04\t0.76\t1.44\t1.03\t2.77\tMuseo Nacional de Eslovenia\t$6.67\t$47.80\t$16.98\t$149.37\t$47.28
Sudáfrica\tSouth Africa\tAfrica\t4.13\t8.11\t33.78\t1\t1.33\t0.68\t1.89\t\t\t$39.05\t$14.35\t$122.04\t$34.38
Corea del Sur\tSouth Korea\tAsia\t5.53\t6.7\t33.51\t1.16\t1.05\t0.66\t3.35\t\t\t$37.76\t$16.04\t$118.00\t$37.06
España\tSpain\tEuropa\t7.78\t11.09\t38.81\t0.62\t1.55\t1.2\t2.77\tSagrada Familia\t$18.93\t$63.06\t$17.09\t$197.07\t$66.39
Sri Lanka\tSri Lanka\tAsia\t4.42\t1.38\t13.81\t0.54\t0.11\t0.28\t1.66\t\t\t$14.36\t$9.42\t$44.88\t$20.40
Suecia\tSweden\tEuropa\t8.51\t10.53\t63.18\t1.45\t3.16\t1.68\t6.84\tPalacio Real de Estocolmo\t$17.00\t$87.73\t$27.72\t$274.16\t$80.04
Suiza\tSwitzerland\tEuropa\t13.18\t25.38\t101.52\t0.86\t3.45\t3.96\t7.11\tCastillo de Chillón, Veytaux\t$12.72\t$126.77\t$35.73\t$396.15\t$107.95
Taiwan\tTaiwan\tAsia\t4.14\t3.61\t22.98\t1.13\t0.66\t0.66\t1.64\t\t\t$25.63\t$10.21\t$80.10\t$24.55
Tailandia\tThailand\tAsia\t6.06\t1.98\t21.43\t0.54\t0.82\t0.28\t2.64\t\t\t$23.15\t$10.20\t$72.35\t$26.48
Túnez\tTunisia\tAfrica\t2.98\t1.75\t14.01\t0.24\t0.19\t0.3\t1.4\t\t\t$14.59\t$11.67\t$45.60\t$20.00
Turquía\tTurkey\tEuropa\t3.46\t4.31\t15.5\t0.33\t0.52\t0.53\t2.58\tSanta Sofía\t$16.36\t$33.30\t$15.46\t$104.07\t$44.42
Ucrania\tUkraine\tEuropa\t4.24\t5.29\t21.15\t0.51\t0.3\t0.3\t1.06\tMuseo de la Gran Guerra Patriótica\t$1.27\t$24.64\t$9.66\t$76.99\t$25.81
Emiratos Árabes Unidos\tUnited Arab Emirates\tMedio Oriente\t6.81\t6.81\t50\t0.62\t1.36\t0.54\t10.89\t\t\t$53.41\t$22.90\t$166.90\t$46.67
Reino Unido\tUnited Kingdom\tEuropa\t7.21\t15.76\t59.12\t1.22\t3.28\t2.45\t4.73\tTorre de Londres, Londres\t$32.59\t$101.05\t$24.89\t$315.79\t$95.44
Estados Unidos\tUnited States\tAmerica\t7.5\t15\t50\t1.79\t2.25\t1.71\t4.5\t\t\t$60.04\t$23.71\t$187.62\t$60.00
Uruguay\tUruguay\tAmerica\t7.64\t9.26\t34.38\t1.17\t1\t0.66\t2.12\t\t\t$39.70\t$15.28\t$124.06\t$42.99
Vietnam\tVietnam\tAsia\t4.31\t2.16\t17.27\t0.51\t0.3\t0.52\t0.86\t\t\t$18.32\t$9.10\t$57.24\t$20.99
`;

const _countries = (function(raw) {
  return raw.split(/\n/)
    .filter(a => a)
    .map(a => {
      const [
        name,,
        continent,
        franchise,
        cheap,
        medium,
        water_lts,
        public_transport,
        taxi,
        beer,
        attraction_name,
        attraction_cost,
        hotel_night,
        hostel_shared,
        daily_cost,
        backpacker_cost
      ] = a.split(/\t/);
      return {
        name,
        continent,
        water_lts,
        beer,
        daily_cost,
        backpacker_cost,
        restaurants: {
          franchise,
          cheap,
          medium,
        },
        transport: {
          public_transport,
          taxi,
        },
        attraction: {
          name: attraction_name,
          cost: attraction_cost
        },
        lodging: {
          hotel: hotel_night.substring(1),
          hostel: hostel_shared.substring(1),
        }
      }
    })
    .filter(({ continent }) => continent === 'Europa')
})(raw);

class Country {
  lodgingPrice(travellers, type) {
    switch (type) {
      case 'lodging.hostel':
        return +this.lodging.hostel;
      case 'lodging.hotel':
        return Math.ceil(travellers / 2) * +this.lodging.hotel;
    }
  }

  restaurantsPrice(type) {
    switch (type) {
      case 'restaurants.franchise':
        return (2 * +this.restaurants.franchise) + +this.restaurants.cheap + +this.water_lts;
      case 'restaurants.cheap':
        return (2 * +this.restaurants.cheap) + +this.restaurants.medium + +this.water_lts;
      case 'restaurants.medium':
        return (2 * +this.restaurants.medium) + +this.restaurants.cheap + +this.water_lts;
    }
  }

  transportPrice(type) {
    switch (type) {
      case 'null':
        return 0;
      case 'transport.public_transport':
        return 4 * +this.transport.public_transport;
      case 'transport.taxi':
        return 3 * +this.transport.taxi;
    }
  }
}

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1.15em;
`

const Flex = styled.div`
  display: flex;
`

function Options({ options }) {
  return options.map(({  value, text }, index) => (
    <option key={index} value={value}>{ text }</option>
  ))
}

const lodgingOptions = [
  { value: 'lodging.hostel', text: 'Hostal habitación compartida' },
  { value: 'lodging.hotel', text: 'Habitación privada en hotel nivel medio' },
];

const restaurantOptions = [
  { value: 'restaurants.franchise', text: 'McDonalds o similar' },
  { value: 'restaurants.cheap', text: 'Restaurantes nivel bajo' },
  { value: 'restaurants.medium', text: 'Restaurante de nivel medio' },
];

const transportOptions = [
  { value: 'null', text: 'Caminar' },
  { value: 'transport.public_transport', text: 'Usaré transporte público' },
  { value: 'transport.taxi', text: 'Prefiero taxi' },
];

const alcoholOptions = [
  { value: 'daily.2', text: 'Una o dos cervezas por día' },
  { value: 'daily.4', text: 'Tres o cuatro cervezas por día' },
  { value: 'weekly', text: 'Una o dos cervezas por semana' },
  { value: 'null', text: 'No tomo' },
]

const Form = ({ onChange, places, setPlaces }) => {
  const [countries] = useState(_countries);
  const [value, updateValue] = useState({
    travellers: 1,
    lodging_type: 'lodging.hostel',
    restaurant_type: 'restaurants.franchise',
    transport_type: 'null',
    alcohol_count: 'daily.2',
    places: []
  })

  const setValue = ({ target }) => {
    value[target.name] = target.value;
    update();
  }

  const addPlace = () => {
    setPlaces(places => places.concat({
      id: Math.random().toString(36).substr(2, 9),
      country: 0,
      days: 1
    }));
  }

  const update = () => {
    const travellers = +value.travellers;
    value.total = value.places.reduce((_total, place) => {
      if (place._delete)
        return _total;
      const days = place.days;
      const country = Object.assign(new Country(), countries[place.country]);
      _total += days * country.lodgingPrice(travellers, value.lodging_type);
      _total += days * travellers * country.restaurantsPrice(value.restaurant_type);
      _total += days * country.transportPrice(value.transport_type);
      if (value.alcohol_count === 'daily.2')
        _total += 2.5 * days;
      else if (value.alcohol_count === 'daily.4')
        _total += 3.5 * days;
      else if (value.alcohol_count === 'weekly')
        _total += (Math.floor(days / 7) + 1) * 1.5;
      else if (value.alcohol_count === 'null')
        _total += 0;
      return _total;
    }, 0);
    updateValue(value)
    onChange(value)
    setPlaces(() => value.places.filter(({ _delete }) => !_delete))
  }

  const _onChange = (key, name, val) => {
    value.places = places.map((place) => {
      return place.id !== key
        ? place
        : { ...place, [name]: val }
    })
    update()
  }

  return (
    <div css={{ padding:'1em' }}>
      <FormGroup css={{ display: 'flex' }}>
        <div className="form-tag" css={{
          paddingRight: '15px',
          marginRight: '10px',
          marginBottom: 0
        }}>
          <div className="icon" css={{ fontSize: '28px' }}>
            <UserIcon />
          </div>
          <label className="form-label" htmlFor="travellers" css={{ marginLeft: '5px' }}>
            ¿Cuantas personas viajan?
          </label>
        </div>
        <input id="travellers" className="form-control" type="text"
               inputMode="numeric" name="travellers"
               value={value.travellers} onChange={setValue}
               required/>
      </FormGroup>
      <form>
        <FormGroup>
          <Flex>
            <div className="form-tag" style={{
              flex: 1,
              paddingRight: '10px',
              marginRight: '0.75rem'
            }}>
              <div className="icon">
                <LocationIcon />
              </div>
              <span className="form-label" style={{ marginLeft: '5px' }}>
                ¿A dónde viajan?
              </span>
            </div>
            <div className="form-tag" style={{ paddingRight: '10px' }}>
              <div className="icon">
                <CalendarIcon />
              </div>
              <span className="form-label" style={{ margiLeft: '5px' }}>
              ¿Cuantos días?
            </span>
            </div>
          </Flex>
          <PlacesForm places={places} countries={countries} onChange={_onChange} />
          <FormButton onClick={addPlace} disabled={places.length > 4}>
            Agregar otro país
          </FormButton>
        </FormGroup>
        <FormGroup>
          <Group>
            <Group.Label className="form-tag">
              <div className="icon">
                <HotelIcon />
              </div>
              <span className="form-label">¿Que tipo de hospedaje prefieres?</span>
            </Group.Label>
            <Group.Select name="lodging_type" onChange={setValue}>
              <Options options={lodgingOptions} />
            </Group.Select>
          </Group>
        </FormGroup>
        <FormGroup>
          <Group>
            <Group.Label className="form-tag">
              <div className="icon">
                <RestaurantIcon />
              </div>
              <span className="form-label">¿En dónde prefieres comer?</span>
            </Group.Label>
            <Group.Select name="restaurant_type" onChange={setValue}>
              <Options options={restaurantOptions} />
            </Group.Select>
          </Group>
        </FormGroup>
        <FormGroup>
          <Group>
            <Group.Label className="form-tag">
              <div className="icon">
                <TrainIcon />
              </div>
              <span className="form-label">¿Transporte preferido en la ciudad?</span>
            </Group.Label>
            <Group.Select name="transport_type" onChange={setValue}>
              <Options options={transportOptions} />
            </Group.Select>
          </Group>
        </FormGroup>
        <FormGroup>
          <Group>
            <Group.Label className="form-tag">
              <div className="icon">
                <BeerIcon />
              </div>
              <span className="form-label">¿Tomas alcohol?</span>
            </Group.Label>
            <Group.Select name="alcohol_count" onChange={setValue}>
              <Options options={alcoholOptions} />
            </Group.Select>
          </Group>
        </FormGroup>
      </form>
    </div>
  )
};

export default Form;
